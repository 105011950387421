import { useReducer, useState } from 'react'
import './App.css';

const YOUTUBE_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/youtube.png'
const FACEBOOK_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/facebook.png'
const LINKEDIN_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/linkedin.png'
const TWITTER_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/twitter.png'
const INSTAGRAM_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/instagram.png'
const PHONE_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/phone.png'
const MOBILE_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/mobile.png'
const LINE_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/line.png'
const VERTICAL_LINE_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/line_vertical.png'
const LOGO_URL = 'https://cambo-media.s3.eu-west-1.amazonaws.com/upstream/upstream-logo.png'
// const LOGO_URL = 'https://drive.google.com/uc?export=view&id=1gZzzf9ce1O_qC6JLitoZy9jCvMjTeJKC'
// const MOBILE_URL = 'https://drive.google.com/uc?export=view&id=1ilXbKPYC1mOcW07XH_HwuYgxpJzO6M5z'
// const PHONE_URL = 'https://drive.google.com/uc?export=view&id=1Q5rVNqI9t4ws4cLhP_rT5qPdG9brju4m'


const initState = {
	cname: 'John Doe',
	cjob: 'Executive Producer',
	cextmob: '+&zwnj;30',	
	cexttel: '+&zwnj;30',
	cmob: '6&zwnj;93&nbsp;1&zwnj;23&nbsp;4&zwnj;567',
	ctel: '2&zwnj;10&nbsp;1&zwnj;23&nbsp;4&zwnj;567',
	cmobtel: '00306931234567',
	cteltel: '00302101234567',
	copyMessage: 'Copy',
	flag: 'init',
	mobileActive: true,
	landlineActive: true,
}

const dataReducer = (state, action) => {

	const { type, payload } = action


	const clean = (phone) => {
		return (phone).replace(/\D/g, '')		
	}

	const insertZwnjAfterFirstCharacter = (str) => {
		const zwnj = '&zwnj;'
		const original = str.split('')
		original.splice(1, 0, zwnj)
		const new_string = original.join('')
		return new_string
	}

	const phoneFormat = (phone) => {
		// const cleaned = ('' + payload).replace(/\D/g, '')
		const cleaned = clean('' + payload)
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

		return (match)
			? insertZwnjAfterFirstCharacter(match[1]) + '&nbsp;' + insertZwnjAfterFirstCharacter(match[2]) + '&nbsp;' + insertZwnjAfterFirstCharacter(match[3])
			: '...'
	}

	const stringFormat = (string) => {
		return (string.trim() === '')
			? '...'
			: string.trim()
	}	


	switch(type) {

		case 'updateName':
			return { ...state, cname: stringFormat(payload) }
		case 'updateJob':
			return { ...state, cjob: stringFormat(payload) }
		case 'updateMobileExtension':
			const newMobileExtension = insertZwnjAfterFirstCharacter(payload)
			return { ...state, cextmob: newMobileExtension }
		case 'updatePhoneExtension':
			const newPhoneExtension = insertZwnjAfterFirstCharacter(payload)
			return { ...state, cexttel: newPhoneExtension }
		case 'updateMobile':						
			return { 
				...state, 
				cmob: phoneFormat(payload),
				cmobtel: '00' + clean(state.cexttel) + clean(payload),
			}
		case 'updatePhone':
			return { 
				...state, 
				ctel: phoneFormat(payload),
				cteltel: '00' + clean(state.cextmob) + clean(payload),
			}
		case 'updateFlag':
			return { ...state, flag: payload }
		case 'updateCopyMessage':
			return { ...state, successResetMsg: payload }
		case 'updateMobileActive':
			return { ...state, mobileActive: payload }
		case 'updateLandlineActive':
			return { ...state, landlineActive: payload }
		case 'resetState':			
			return { ...state, ...initState }
		default:
			throw new Error()

	}

}

function App() {

	const [ state, dispatch ] = useReducer(dataReducer, initState)

	const [ emailClient, setEmailClient ] = useState('gmail')	
	
	const copySignature = () => {

		let text = document.getElementById('upstream-signature-html');
		let selection = window.getSelection();
		let range = document.createRange();
		range.selectNodeContents(text);
		selection.removeAllRanges();
		selection.addRange(range);
		//add to clipboard.
		document.execCommand('copy');
		
	}

	const copyClickHandler = () => {

		if (state.flag === 'loading')
			return

		dispatch({
			type: 'updateFlag',
			payload: 'loading'
		})

		dispatch({
			type: 'updateCopyMessage',
			payload: 'Copying ... '
		})

		copySignature()

		setTimeout( () => {

			dispatch({
				type: 'updateCopyMessage',
				payload: 'Copied'
			})

			dispatch({
				type: 'updateFlag',
				payload: 'init'
			})
			
		}, 500)
					

	}


	const resetClickHandler = () => {

		if (state.flag === 'loading')
			return

		dispatch({
			type: 'resetState'
		})

		document.getElementById('theForm').reset()

	}


	return(
		<div className="App">
			<div id="wrap">

				<div id="form-wrap">
				
					<form id="theForm">
						<label>
							<input type="text" placeholder="Fullname" onChange={ (e) => { dispatch({ type: 'updateName', payload: e.target.value })}} />
							<span>FirstName LastName</span>
							<em>First and Last Name</em>
						</label>

						<label>
							<input type="text" placeholder="Job Position"  onChange={ (e) => { dispatch({ type: 'updateJob', payload: e.target.value })}} />
							<span>Job Position</span>
							<em>Your Job Position</em>
						</label>

						<label style={{ width: '50px'}}>
							<input style={{padding: '20px 2px', textAlign: 'center' }} type="text" placeholder="Code" onChange={ (e) => { dispatch({ type: 'updateMobileExtension', payload: e.target.value })}} />
							<span style={{left:'50%',transform:'translate(-50%,0)'}}>Code</span>
							<em>Code</em>
						</label>
						
						<label>
							<input type="text" placeholder="Mobile" onChange={ (e) => { dispatch({ type: 'updateMobile', payload: e.target.value })}} />
							<span>Mobile</span>
							<em>Your mobile</em>
						</label>

						<label style={{ width: '50px'}}>
							<input style={{padding: '20px 2px', textAlign: 'center' }} type="text" placeholder="Code" onChange={ (e) => { dispatch({ type: 'updatePhoneExtension', payload: e.target.value })}} />
							<span style={{left:'50%',transform:'translate(-50%,0)'}}>Code</span>
							<em>Code</em>
						</label>

						<label>
							<input type="text" placeholder="Phone" onChange={ (e) => { dispatch({ type: 'updatePhone', payload: e.target.value })}} />
							<span>Phone</span>
							<em>Your phone number</em>
						</label>

						<div className="clearfix"></div>
						
					</form>

					<div className="subform">
						
						<section>
							<div className="client-select">
								<h3>Choose Email Client</h3>
								<div className="client-select-options">
									<label><input type="checkbox" onChange={ (e) => { setEmailClient('gmail') }} checked={ emailClient === 'gmail'} /> <span>Gmail</span></label>
									<label><input type="checkbox" onChange={ (e) => { setEmailClient('outlook') }} checked={ emailClient === 'outlook'} /> <span>Outlook</span></label>								
								</div>								
							</div>							
						</section>

						<section>
							<div className="client-select">
								<h3>Active Phones</h3>
								<div className="client-select-options">
									<label><input type="checkbox" onChange={ (e) => { dispatch({ type: 'updateMobileActive', payload: !state.mobileActive }) } } checked={state.mobileActive} /> <span>Mobile</span></label>
									<label><input type="checkbox" onChange={ (e) => { dispatch({ type: 'updateLandlineActive', payload: !state.landlineActive }) } } checked={state.landlineActive} /> <span>Landline</span></label>
								</div>
							</div>
						</section>

						<section>
							<div style={{margin:'20px auto'}} className="buttons">
								<button
									className="selectable"
									onClick={ () => { copyClickHandler() }}									
									style={{ backgroundColor: 'rgb(128 189 1 / 1)'}}
								>					
									<div className="svg">
										<div className="svg-inner" style={{paddingBottom:'116.6666666667%'}}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 490"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M35,0H280c19.33,0,35,19.33,35,35V350a35,35,0,0,1-35,35H35c-19.33,0-35-19.33-35-35V35A35,35,0,0,1,35,0Z"/><path d="M420,157.5V455a35.1,35.1,0,0,1-35,35H140a35.1,35.1,0,0,1-35-35V420H280a70,70,0,0,0,70-70V122.5h35a35.1,35.1,0,0,1,35,35Z"/></g></g></svg>
										</div>										
									</div>
									<div className="msg">{ state.copyMessage }</div>
								</button>

								<button
									className="selectable"
									onClick={ () => { resetClickHandler() }}									
								>
									<div className="svg">
										<div className="svg-inner" style={{paddingBottom:'124.9986607382%'}}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.34 466.67"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fillRule="evenodd" d="M0,186.67V210H46.67V186.67c0-77.21,62.79-140,140-140s140,62.79,140,140-62.79,140-140,140H163.34V233.34L46.67,350,163.34,466.67V373.34h23.33c102.92,0,186.67-83.75,186.67-186.67S289.59,0,186.67,0,0,83.75,0,186.67Z"/></g></g></svg>
										</div>
									</div>
									<div className="msg">Reset</div>
								</button>



								{/* { state.flag && <button id="selectable" onClick={ () => { copySignature() }}>Select Signature</button> }
								{ !state.flag && 
								<button 
									id="selectable" 
									onClick={ () => { 
										dispatch({ type: 'resetState' })
										formRef.current.reset()
									}} 
									style={{backgroundColor:'rgba(128,189,1,1)'}}
								>
									<div style={{float:'left',width:'28px',height:'28px',marginTop:'-5px',fill:'#FFF'}}>
										
									</div> 
								</button> } */}
							</div>
						</section>

					</div>						

				</div>

					<div id="upstream-signature-html-wrap">

						<div className="upstream-signature-wrap-bg">
							<img src="https://cambo-media.s3.eu-west-1.amazonaws.com/cambo/sgntr/email-tpl.png" alt="" />
						</div>

						<div id="upstream-signature-html">
														
							{/*********************************************************************************************************************/}
							
								<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', borderCollapse: 'collapse'}} cellSpacing="0" cellPadding="0" border="0" width="100%" role="presentation">
									<tbody>
										<tr style={{ margin: 0, padding: 0 }}><td colSpan="3" style={{padding:0,margin:0,fontSize:0,height:0}} height="0">&zwnj;</td></tr>
										<tr style={{ margin: 0, padding: 0 }}>
										<td valign="top" align="left" style={{padding:0,margin:0}}>&zwnj;</td>
											<td valign="top" align="left" style={{padding:0,margin:0}}>
								
												<div style={{ width: '100%', margin: 0, padding: 0 }}>											
													<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', textAlign: 'left', borderCollapse: 'collapse' }} cellSpacing="0" cellPadding="0" border="0" width="100%" role="presentation">
														<tbody>
															<tr style={{ margin: 0, padding: 0 }}>
																<td valign="top" align="left">
																	<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', borderCollapse: 'collapse'}} cellSpacing="0" cellPadding="0" border="0" width="344" role="presentation">
																		<tbody>
																			<tr style={{ margin: 0, padding: 0 }}>
																				<td valign="top" align="left" colSpan="2" style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '16px', fontWeight: '400', lineHeight: '16px', color: '#eb6400', padding: '0 0 8px' }}><span style={{ fontFamily: "'Montserrat', Verdana, sans-serif" }} className="textor">{ state.cname }</span></td>
																			</tr>																		
																			<tr style={{ margin: 0, padding: 0 }}>
																				<td valign="top" align="left" colSpan="2" style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '13px', fontWeight: '400', lineHeight: '13px', color: '#464646', padding: '0 0 16px' }}><span style={{ fontFamily: "'Montserrat', Verdana, sans-serif" }} className="textbw">{ state.cjob }</span></td>
																			</tr>																		
																			{ state.mobileActive && 
																			<tr style={{ margin: 0, padding: 0 }}>
																				<td valign="top" align="left" width="16" style={{ fontFamily: "'Montserrat', Verdana, sans-serif", padding: '0 0 8px 0', textDecoration: 'none', msoLineHeightRule: 'exactly' }}><img style={{ display: 'inline-block' }} src={MOBILE_URL} width="18" height="18" alt="Phone:" /></td>
																				<td valign="top" align="left" width="328" style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '11px', lineHeight: '18px', padding: '0 0 0 0', color: '#464646', msoLineHeightRule: 'exactly' }}><span style={{ fontFamily: "'Montserrat', Verdana, sans-serif", textDecoration: 'none', color: '#464646' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cextmob}&nbsp;${state.cmob}`}}/></td>
																			</tr>	
																			}								
																			{ state.landlineActive && 
																			<tr style={{ margin: 0, padding: 0 }}>
																				<td valign="top" align="left" width="16" style={{ fontFamily: "'Montserrat', Verdana, sans-serif", padding: '0 0 0 0', textDecoration: 'none', msoLineHeightRule: 'exactly' }}><img style={{ display: 'inline-block' }} src={PHONE_URL} width="18" height="18" alt="Mobile:" /></td>
																				<td valign="top" align="left" width="328" style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '11px', lineHeight: '18px', padding: '0 0 0 0', color: '#464646', msoLineHeightRule: 'exactly' }}><span style={{ fontFamily: "'Montserrat', Verdana, sans-serif", textDecoration: 'none', color: '#464646' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cexttel}&nbsp;${state.ctel}`}}/></td>
																			</tr>
																			}
																			<tr style={{ margin: 0, padding: 0 }}>
																				<td valign="top" align="left" colSpan="2" style={{ padding: (!state.mobileActive && !state.landlineActive) ? '15px 0 0 0'  : '30px 0 0 0'}}><img style={{ display: 'inline-block' }} src={LINE_URL} width="344" height="3" alt="---" /></td>
																			</tr>
																			<tr style={{ margin: 0, padding: 0 }}>
																				<td valign="top" align="left" colSpan="2" style={{ padding: '28px 0 0 0' }}>																	
																					<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', borderCollapse: 'collapse'}} cellSpacing="0" cellPadding="0" border="0" width="344" role="presentation">
																						<tbody>
																							<tr style={{ margin: 0, padding: 0 }}>
																								<td width="145" style={{ textAlign: 'left', fontFamily: "'Montserrat', Verdana, sans-serif",  padding: '0 0 0 0' }}><a href="https://www.upstreamsystems.com/" style={{ textDecoration: 'none' }}><img style={{ display: 'inline-block' }} src={LOGO_URL} width="145" height="40" alt="Upstream" /></a></td>
																								<td width="71" style={{ textAlign: 'center', fontFamily: "'Montserrat', Verdana, sans-serif", padding: '0 0 0 0' }}>&nbsp;&nbsp;&nbsp;<img style={{ display: 'inline-block' }} src={VERTICAL_LINE_URL} width="1" height="31" alt="Upstream" />&nbsp;&nbsp;&nbsp;</td>
																								<td width="128" style={{ fontFamily: "'Montserrat', Verdana, sans-serif", padding: '0 0 0 0' }}>
																									<div style={{ fontSize: '13px', fontWeight: '400', lineHeight: '13px', margin: '0 0 0 0', color: '#eb6400' }}><span style={{ fontFamily: "'Montserrat', Verdana, sans-serif" }} className="textor">Follow us</span></div>																				
																									<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', borderCollapse: 'collapse'}} cellSpacing="0" cellPadding="0" border="0" width="140" role="presentation" align="left">
																										<tbody>
																											<tr style={{ margin: 0, padding: 0 }}>
																												<td valign="middle" style={{ verticalAlign: 'middle', padding: '8px 6px 0 0' }}><a href="https://www.facebook.com/upstreamsystems/" style={{ textDecoration: 'none' }}><img src={FACEBOOK_URL} width="10" height="19" alt="Facebook" /></a></td>
																												<td valign="middle" style={{ verticalAlign: 'middle', padding: '8px 6px 0 6px' }}><a href="https://www.linkedin.com/company/upstream/" style={{ textDecoration: 'none' }}><img src={LINKEDIN_URL} width="15" height="15" alt="LinkedIn" /></a></td>
																												<td valign="middle" style={{ verticalAlign: 'middle', padding: '8px 6px 0 6px' }}><a href="https://twitter.com/UpstreamGlobal/" style={{ textDecoration: 'none' }}><img src={TWITTER_URL} width="16" height="13" alt="Twitter" /></a></td>
																												<td valign="middle" style={{ verticalAlign: 'middle', padding: '8px 6px 0 6px' }}><a href="https://www.youtube.com/user/UpstreamMobileMktg/" style={{ textDecoration: 'none' }}><img src={YOUTUBE_URL} width="18" height="13" alt="Youtube" /></a></td>
																												<td valign="middle" style={{ verticalAlign: 'middle', padding: '8px 0 0 6px' }}><a href="https://www.instagram.com/weareupstream_/" style={{ textDecoration: 'none' }}><img src={INSTAGRAM_URL} width="17" height="16" alt="Instagram" /></a></td>
																											</tr>
																										</tbody>
																									</table>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</td>
															</tr>
														</tbody>
													</table>								
											
												</div>	

											</td>
											<td valign="top" align="left" style={{padding:0,margin:0}}>&zwnj;</td>
										</tr>
										<tr><td valign="top" align="left" colSpan="3" style={{padding:0,margin:0,fontSize:0,height:0}} height="0">&zwnj;</td></tr>
									</tbody>
								</table>
							
							{/*********************************************************************************************************************/}
							
							{/*							
							<table cellpadding="0" cellspacing="0" border="0" width="300" style={{ height:'200px', lineHeight: '1px', fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '8px', backgroundColor: '#fff', color: '#999' }}>
								<tr>
									<td width="310" height="40" style={{ msoLineHeightRule: 'exactly', lineHeight: '140%', fontSize: '13px', fontFamily: "'Montserrat', Verdana, sans-serif", fontWeight: '400', color: '#229790'}}>&nbsp;<br />
										<strong style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '16px',  fontWeight: '400', color: '#eb6400'}}> { state.cname }</strong><br/>
										<span style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '14px',  fontWeight: '400', color: '#464646'}}> { state.cjob }</span><br/>
									</td>
								</tr>
								<tr>
									<td width="310" height="30" style={{ msoLineHeightRule: 'exactly', lineHeight: '160%', fontSize: '12px', fontFamily: "'Montserrat', Verdana, sans-serif", fontWeight: '400', color: '#999999' }}>
										<a href={`tel:${state.cmobtel}`}><img src={'https://i.postimg.cc/tTgdtz2S/Asset-9.png'} width={11} height={11} style={{ width: '11px', height: '11px' }} alt={'Mobile'} /></a>
										<span style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '11px', lineHeight: '10px', fontWeight: '400', color: '#000', textDecoration: 'none' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cextmob}&nbsp;${state.ctel}`}} /><br/>
										<a href={`tel:${state.cteltel}`}><img src={'https://i.postimg.cc/xdn3qQHS/Asset-8.png'} width={11} height={11} style={{ width: '11px', height: '11px' }} alt={'Phone'} /></a>
										<span style={{ fontFamily: "'Montserrat', Verdana, sans-serif", fontSize: '11px', lineHeight: '10px', fontWeight: '400', color: '#000', textDecoration: 'none' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cextmob}&nbsp;${state.cmob}`}} />
									</td>
								</tr>
								<tr>
									<td width="500" height="10" colspan="2"  style={{ msoLineHeightRule: 'exactly', lineHeight: '140%', fontSize: '12px', fontFamily: "'Montserrat', Verdana, sans-serif", fontWeight: '400', color: '#000' }}>
										<a href="https://www.upstreamsystems.com/"><img src={'https://i.postimg.cc/8CSGzsKk/Asset-10.png'} width={266} height={18} style={{ width: '266px', height: '18px' }} alt={'………………………'} /></a>
									</td>
								</tr>
								<tr>
									<td width="170" height="45">
										<a href={'https://www.upstreamsystems.com/'}><img src="https://i.postimg.cc/76NZ0qS3/Email-Signature-Design2-01.png" width={150} height={32}  alt={'Upstream'} /></a>
									</td>
									<td width="350" height="45">
										<img src="https://i.postimg.cc/7LMN9X2S/Asset-11.png" height="12" width="" alt=""/><br />
										<a href={'https://www.facebook.com/upstreamsystems/'}><img src="https://i.postimg.cc/kGhs84tZ/Asset-3.png" width=" " height="12" alt="Facebook"/></a> &nbsp; &nbsp;
										<a href={'https://www.linkedin.com/company/upstream/'}><img src="https://i.postimg.cc/RVNXmckz/Asset-4.png" width=" " height="12" alt="Linkedin"/></a> &nbsp; &nbsp;
										<a href={'https://twitter.com/UpstreamGlobal/'}><img src="https://i.postimg.cc/pLDB2rmZ/Asset-5.png" width=" " height="10" alt="Twittter"/></a> &nbsp; &nbsp;
										<a href={'https://www.youtube.com/user/UpstreamMobileMktg/'}><img src="https://i.postimg.cc/PxFW687t/Asset-6.png" width=" " height="12" alt="Youtube"/></a> &nbsp;&nbsp;
										<a href={'https://www.instagram.com/weareupstream_/'}><img src="https://i.postimg.cc/hGsbdYbQ/Asset-7.png" width=" " height="12" alt="Instagram"/></a> &nbsp;
									</td>
								</tr>
							</table>
							*/}

						</div>
					</div>					
				
			</div>
		</div>
	)

}

export default App